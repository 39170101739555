*,
*::before,
*::after {
  padding: 0;
  margin: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}
html {
  font-size: 100%;
  line-height: 1.4;
  scroll-behavior: smooth;
}
h1,
p,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 50px;
  -webkit-border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 0.4s;
  background: var(--primary);
}


/* fonts added */
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
   url("./fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "OswaldVariableFontWght";
  src: local("OswaldVariableFontWght"),
   url("./fonts/Oswald-VariableFont_wght.ttf") format("truetype");
  font-weight: 300 !important;
}

.heading-font{
  font-family: "RobotoRegular" !important;
}

.paragraph-font{
  font-family: "OswaldVariableFontWght" !important;
}