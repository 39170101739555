.exchange-section .border-box-exchange{
    width: 60%;
    height: 420px;
}

.exchange-section .border-box-exchange .border-box-exchange-inner{
    height: 400px;
}

.feature-section{
    padding-top: 100px;
}
.paragraph-p{
    
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .exchange-section .border-box-exchange{
        width: 500px;
        height: 350px;
    }
    .exchange-section .border-box-exchange .border-box-exchange-inner{
        height: 330px;
    }
}

@media screen and (max-width: 767px) {
    .exchange-section .border-box-exchange{
        width: 100%;
        height: 350px;
    }
    .exchange-section .border-box-exchange .border-box-exchange-inner{
        height: 330px;
    }
}
.features-p{
    text-align: 'center';
    margin-top:1%;
}