.token-section{}

.token-section .evenly-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.token-section .get-content-text{
    width: 200px;
}
.token-section .left-sec{
    width:50%;
    text-align: left;
    margin-bottom:0px;
}
.token-section .evenly-content{
    margin-top:1%;
}
.token-section .evenly-content .evenly-content-img{
    width: 50% !important;
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .token-section .evenly-content .evenly-content-img{
        width: 50% !important;
    }
    .token-section .left-sec{
        width:50%;
        text-align: left;
        margin-bottom:0px;
    }
    .token-section .left-sec h3{
        font-size: 18px;
    }
}

@media screen and (max-width: 767px){
    .token-section .evenly-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
    }
    .token-section .evenly-content .evenly-content-img{
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .token-section .get-content-text{
        width: 100%;
    }
    .token-section .left-sec{
        width:100%;
        text-align: left;
        margin-bottom:0px;
    }
    .token-section .left-sec h3{
        font-size: 14px;
    }
}
.token-allocation{
    margin-top: 5%;
}