.dashboard-heading-large{
    color: var(--white);
    font-size: 30px;

}

.dashboard-heading-small{
    color: var(--white);
    opacity: 50%;
    font-size: 18px;
    
}
.bg-line{
    width:40%;
}
.dashboard-conis{
    width: 45%;
    height: 100px;
    background: transparent linear-gradient(269deg, #F98A07 0%, #F98A07 14%, #F3B72C 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-conis span:nth-child(1){
    font-size: 25px;
    margin: 0 !important;
    padding: 0 !important;
}

.dashboard-conis span:nth-child(2){
    font-size: 16px;
    margin: 0px !important;
    padding: 0 !important;
}
.small-screen-logo{
    display: none;
}

.withdraw-container{
    margin-left: auto;
}

.withdraw-container .withdraw-input{
    width: 245px;
    border: none !important;
    outline: none !important;
    padding: 10px 12px;
    background-color: var(--secondary);
    border-radius: 6px;
    color: var(--white);
}

.withdraw-container .withdraw-input::placeholder{
    color: var(--roadMap);
    font-weight: 500;
}

.withdraw-container .withdraw-button{
    padding: 10px 22px;
    background-color: var(--white);
    border-radius: 6px;
    color: var(--black);
    font-size: 15px;
    border: none !important;
    outline: none !important;
    text-transform: capitalize;
    margin-left: 6px;
    font-weight: 500;
}
.dashboard-parent{
    margin-left: 35%;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .dashboard-conis {
        width: 59%;
    }
    .withdraw-container .withdraw-input{ width: 100%; }
    .withdraw-container .withdraw-button{
        width: 100%;
        margin-top: 7px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 991px) {

    .heading-wrapper{
        display: flex;
        flex-direction: column !important;
    }

    .withdraw-container{
        margin-left: 0;
        padding-bottom: 10px;
    }

    .dashboard-conis {
        width: 100%;
        height:80px;
    }
    .dashboard-sidebar{
        display: none;
    }
    .dashboard-heading-large{
        text-align: center;
    }
    .dashboard-heading-small{
        text-align: center;
    }
    .bg-line{
        margin:0px auto;
        width: 75%;
    }
    .small-screen-logo{
        display: initial;
        width:100%;
        text-align: center;
    }
    .small-screen-logo img{
        margin-right:5%;
        width:90px;
    }
    .dashboard-conis span:nth-child(1){
        font-size: 22px;
        margin: 0 !important;
        padding: 0 !important;
    }
    
    .dashboard-conis span:nth-child(2){
        font-size: 16px;
        margin: 0px !important;
        padding: 0 !important;
    }
    .dashboard-heading-large{
        font-size: 25px;
    }
    .dashboard-parent{
        margin-left: 0%;
    }
    
}
@media screen and (max-width: 575px) {

    .withdraw-container .withdraw-input{ width: 100%; }
    .withdraw-container .withdraw-button{
        width: 100%;
        margin-top: 7px;
        margin-left: 0px;
    }

    .dashboard-conis {
        width: 100%;
        text-align: center;
        height: 70px;
    }
    .dashboard-sidebar{
        display: none;
    }
    .dashboard-conis span:nth-child(1){
        font-size: 18px;
        
    }
    
    .dashboard-conis span:nth-child(2){
        font-size: 14px;
        
    }
    .dashboard-heading-large{
        font-size: 20px;
    }
    .small-screen-logo img{
        margin-right:12%;
        width:70px;
    }
    .dashboard-heading-small{
        font-size: 14px;
    }
    .dashboard-parent{
        margin-left: 0%;
    }
}