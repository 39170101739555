.main-container{
    padding: 150px 120px 5px 120px !important;
    transition: all .4s ease-in-out;    
}

.about-section-container{
    background: url('../../Assets/images/bg-shape-crop.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -90px;
}

.header-bg-img{
    position: absolute;
    left: -18%;
    top: -18%;
    z-index: 10;
    width: 65%;
    height: 31%;
    transform: rotate(22deg);
}

.token-section-container{
    /* background: url('../../Assets/images/bg-shape-crop.png'); */
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .main-container{
        padding: 150px 15px 5px 30px !important;
    }
    .about-section-container{
        margin-top: -40px;   
    }
}

@media screen and (max-width: 991px) {
    .main-container{
        padding: 150px 20px 5px 20px !important;
    }
    .about-section-container{
        margin-top: 0px; 
        height: max-content !important;  
        /* background-size: contai; */
    }
    .header-bg-img{
        display: none;
    }
}

@media screen and (max-width: 767px){
    .about-section-container{
        margin-top: -110px !important; 
        height: max-content !important;  
    }
}