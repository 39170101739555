
.about-right-section h6{
    font-size: 28px;
    font-weight: bold;
}

.about-right-section span{
    font-size: 18px;

}

.about-image{
    width: 90% !important;
    margin-top: 4%;
    /* height: 650px !important; */
}

@media screen and (min-width: 768px) and (max-width: 1199px){
    .about-right-section .border-box-about{
        width: 100%;
        margin-top: 20px;
        padding: 10px 20px !important;
        margin-left:10px;
    }
    .about-right-section h6{
        font-size: 20px;    
    }
    
    .about-right-section span{
        font-size: 14px;
    }
    .about-image{
        width: 100% !important;
        /* height: 650px !important; */
    }
}

@media screen and (max-width: 767px) {
    .about-right-section .border-box-about{
        width: 100% !important;
        margin-top: 20px !important;
        text-align: center;
    }
    .about-right-section h6{
        font-size: 18px;    
    }
    
    .about-right-section span{
        font-size: 14px;
    }
    .about-image{
        margin-left: 5% !important;
    }
}