/* left */
.header-left-section h6{
    letter-spacing: 1.55px;
    font-size: 62px;
}

.header-left-section p{
    letter-spacing: 0.45px;
    font-size: 18px;
}

.header-left-section button{
    width: 120px;
    height: 45px;
    color: var(--white);
    border-radius: 6px;
}

/* right */
.header-right-section .border-box{
    width: 450px;
    height: 280px;
}

.header-right-section .border-box .time-box{
    width: 80px;
    height: 88px;
    background: #201D25 0% 0% no-repeat padding-box;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 40px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .header-left-section h6{
        font-size: 45px;
    }
    .header-left-section p{
        font-size: 16px;
    }
    .header-right-section .border-box{
        width: 75%;
        height: 280px;
        margin-bottom: 10px;
    }
    .header-right-section .border-box .time-box{
        width: 60px;
        height: 68px;
        font-size: 25px;       
    }
}

@media screen and (max-width: 991px) {
    .header-left-section h6{
        font-size: 30px;
    }
    .header-left-section p{
        font-size: 14px;
    }
    .header-right-section .border-box{
        width: 100% !important;
        height: 280px;
        margin-bottom: 10px;
    }
    .header-right-section .border-box .time-box{
        width: 50px;
        height: 58px;
        font-size: 20px;       
    }
}