.roadmap-img{
    background: url('../../../Assets/images/roadmapD.png');
    background-repeat: no-repeat;
    width:100%;
    height:220px;
    background-size: 100% 100% !important;
    margin-top: 120px;
    margin-bottom: 100px;
}
@media only screen and (max-width: 768px) {
    .roadmap-img{
        background: url('../../../Assets/images/roadmapM.png');
        background-repeat: no-repeat;
        width:80%;
        height: 1150px;
        background-size: contain !important;
        margin: 40px 0 60px 0 !important;
    }
}

@media only screen and (max-width: 585px) {
    .roadmap-img{
        background: url('../../../Assets/images/roadmapM.png');
        background-repeat: no-repeat;
        width:90%;
        height: 600px;
        background-size: contain !important;
        margin: 40px 0 30px 0 !important;
    }
}