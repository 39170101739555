.font-36px{
  font-size: 36px !important;
}

.font-30px{
  font-size: 30px !important;
}

.font-28px{
  font-size: 28px !important;
}

.font-23px{
  font-size: 23px !important;
}

.font-20px{
  font-size: 20px !important;
}

.font-18px{
  font-size: 18px !important;
}

.font-16px{
  font-size: 16px !important;
}

.font-14px{
  font-size: 14px !important;
}

.app-flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
  }
  .app-flex-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
  }
  
  .cursor-pointer-sort {
    cursor: pointer;
  }
  .paragraph-font{
    font-family: 'oswald';
  }
  .gradient-apply{
    background: transparent linear-gradient(269deg, #F98A07 0%, #F98A07 14%, #F3B72C 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


@media screen and (max-width: 767px) {
  
  .remove-margins{
    margin: 10px 0px !important;
  }

  .font-36px{
    font-size: 30px !important;
  }
  
  .font-30px{
    font-size: 25px !important;
  }
  
  .font-28px{
    font-size: 22px !important;
  }
  
  .font-23px{
    font-size: 19px !important;
  }
  
  .font-20px{
    font-size: 17px !important;
  }

  .font-18px{
    font-size: 16px !important;
  }
  
  .font-16px{
    font-size: 15px !important;
  }
  
  .font-14px{
    font-size: 13px !important;
  }
}