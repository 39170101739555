.padding-topbar{
    padding: 15px 80px 10px 80px !important;
    transition: all .4s ease-in-out;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .padding-topbar{
        padding: 20px 30px 10px 30px !important;
    }
    .nav-links{
        width: 72px !important;
    }
}

@media screen and (max-width: 991px) {
    .padding-topbar{
        padding: 10px 20px 5px 20px !important;
    }
    .navbar-nav{
        margin-top: 20px;
    }
    .nav-item{
        width: 100% !important;
    }
    .connect-meta-mask{
        margin: 10px 0px !important;
        width: 100% !important;
    }
    .margin-buy-btn{
        margin: 0 !important;
    }
}

.navbar-light .navbar-toggler{
    color: var(--white) !important;
    border-color: var(--white) !important;
}

.nav-links{
    width: 80px;
    height: 42px;
    border-radius: 50px;
    font-size: 15px;
    margin-top: 2px;
    transition: all .3s ease-in-out;
}

.nav-links:nth-child(7){
    margin-right: 20px;
}

.connect-wallet{
    width: 150px;
    height: 49px;
    border-radius: 6px;
    font-size: 16px;
}

.inactive-link-dark{
    color: black !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.inactive-link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--white);
}

.inactive-link:hover{
    color: var(--white) !important;
    text-decoration: none !important;
}
.margin-buy-btn{
    margin-left: 10px;
}