.sidebar{
    width: 400px;
    height: 100%;
    background: var(--secondary);
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


/* sidebar item css */
.nav-item{
    color: var(--white);
    line-height: 10px;
    padding-bottom: 0px;
    opacity: .8;
    position: relative;
    transition: all .4s;
    font-weight: 400;
    font-size: 14px;
}

.nav-item:hover{
    color: whitesmoke;
    text-decoration: none;
}

.active-nav-item{
    position: relative;
    opacity: 1;
    transition: all .5s;
    background: var(--primary);
    color: var(--white);
    font-weight: 600;
}
.menu{
    font-size: 20px;
}